import * as React from "react"
import './style.scss'
import * as styles from './404.module.scss'
import Layout from '../components/layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not found">
    <div className={styles.row}>
        <h1>Strona nie istnieje</h1>
        <p>
          Niestety tu nic nie ma. {" "}
            <span role="img" aria-label="Pensive emoji">
              😢
            </span>
          <br />
        </p>
    </div>
    </Layout>
  )
}

export default NotFoundPage
